module.exports = {
  menuHome: "home",
  menuWorks: "advertising",
  menuPhotos: "photography",
  menuAbout: "about mp",
  readMore: "Read more",
  worksTitle: "Advertising",
  worksReadCase: "READ CASE",
  worksScroll: "VIEW OTHER PROJECTS",
  aboutTitle: "About MP",
  detailCase: "Case study",
  detailSpot: "TV spot",
  detailChallenge: "Challenge",
  detailIdea: "Idea",
  detailResults: "Results",
  detailExtra: "Extra content",
  photosTitle: "Photography",
  photosAll: "All",
  photosStudio: "Studio",
  photosOutdoors: "Outdoors",
  photosConcerts: "Concerts",
  photosIphone: "Iphone",
};
