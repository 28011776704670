import React from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { getCurrentLangKey } from "ptz-i18n";

import { messages } from "../../locale";
import Header from "../Header";

import "@fontsource/poppins";
import "@fontsource/roboto";
import LocaleContext from "../../context/locale";

function Layout({ children, location, languages, headerProps }) {
  const pathname = location.pathname;
  const { langs, defaultLangKey } = languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, pathname);

  return (
    <LocaleContext.Provider value={{ langKey, languages, pathname }}>
      <IntlProvider locale={langKey} messages={messages[langKey]}>
        <Helmet>
          {/* <!-- Meta Character Set --> */}
          <meta charset="utf-8" />
          <title>Martin Pettinari</title>

          {/* <!-- Responsive Meta Tag --> */}
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          {/* <!-- Meta Description --> */}
          <meta name="description" content="Martin Pettinari Profile" />
          <meta
            name="keywords"
            content="responsive, creative, resume, html5, css3, personal, onepage personal"
          />
          <meta name="author" content="pettinarip" />
          <body className="bg-gradient-to-r from-blue-900 to-blue-400" />
        </Helmet>
        <Header {...headerProps} />
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

export default Layout;
