import { useState, useEffect, useRef, useCallback } from "react";

function useIsSticky(defaultSticky = false) {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const elementRef = useRef(null);

  const toggleSticky = useCallback(() => {
    if (window.pageYOffset > elementRef.current.offsetTop) {
      !isSticky && setIsSticky(true);
    } else {
      isSticky && setIsSticky(false);
    }
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener("scroll", toggleSticky);
    return () => {
      window.removeEventListener("scroll", toggleSticky);
    };
  }, [toggleSticky]);

  return { elementRef, isSticky };
}

export default useIsSticky;
