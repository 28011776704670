const en = require("./messages/en");
const es = require("./messages/es");

module.exports = {
  langs: ["es", "en"],
  defaultLangKey: "en",
  messages: {
    en,
    es,
  },
};
