import React, { useContext } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { getLangs, getUrlForLang } from "ptz-i18n";
import localeSlug from "../../utils/localeSlug";
import LocaleContext from "../../context/locale";
import useIsSticky from "../../hooks/useIsSticky";
import NavMobile from "../NavMobile";
import Nav from "../Nav";

function Header({ className, ...restProps }) {
  const { elementRef, isSticky } = useIsSticky();
  const { langKey, languages, pathname } = useContext(LocaleContext);
  const { langs, defaultLangKey } = languages;

  const homeLink = `/${langKey !== "en" ? langKey : ""}`;
  const langsMenu = getLangs(
    langs,
    langKey,
    getUrlForLang(homeLink, pathname)
  ).map((item) => ({
    ...item,
    link: item.link.replace(`/${defaultLangKey}/`, "/").replace(`//`, `/`),
  }));

  return (
    <header
      ref={elementRef}
      className={classNames(
        `-inset-x-0 top-0 text-white border-b z-10`,
        className,
        { "fixed bg-black": isSticky, absolute: !isSticky }
      )}
      style={{ height: 70, borderColor: "hsl(0deg 0% 100% / 10%)" }}
      {...restProps}
    >
      <div className="container flex mx-auto px-9 h-full items-center">
        <div className="flex-initial font-roboto text-2xl tracking-widest mr-10">
          <Link to={localeSlug(langKey, "/")}>MP</Link>
        </div>
        <Nav langKey={langKey} langsMenu={langsMenu} />
        <NavMobile langKey={langKey} langsMenu={langsMenu} />
      </div>
    </header>
  );
}

export default Header;
