module.exports = {
  menuHome: "inicio",
  menuWorks: "publicidad",
  menuPhotos: "fotografía",
  menuAbout: "sobre mp",
  readMore: "Leer más",
  worksTitle: "Trabajos de publicidad",
  worksReadCase: "VER CASO",
  worksScroll: "TODOS LOS TRABAJOS",
  aboutTitle: "Sobre MP",
  detailCase: "Estudio de caso",
  detailSpot: "Spot de TV",
  detailChallenge: "Desafío",
  detailIdea: "Idea",
  detailResults: "Resultados",
  detailExtra: "Contenido extra",
  photosTitle: "Fotografía",
  photosAll: "Todos",
  photosStudio: "Estudio",
  photosOutdoors: "Exteriores",
  photosConcerts: "Conciertos",
  photosIphone: "Iphone",
};
