import React, { useCallback, useRef, useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import localeSlug from "../../utils/localeSlug";
import { AiOutlineMenu } from "react-icons/ai";
import useClickOutside from "../../hooks/useClickOutside";

function NavMobile({ langKey, langsMenu }) {
  const navRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  const handleOpen = useCallback(() => {
    setToggle(true);
  }, [setToggle]);

  const handleClose = useCallback(() => {
    if (!toggle) {
      return;
    }
    setToggle(false);
  }, [toggle, setToggle]);

  useClickOutside(navRef, handleClose);

  return (
    <div className="flex-1 md:hidden">
      <button className="float-right" onClick={handleOpen}>
        <AiOutlineMenu />
      </button>
      <nav
        ref={navRef}
        className="md:hidden fixed inset-y-0 transition-all font-roboto text-md uppercase tracking-wider bg-black max-w-full"
        style={{ width: 270, right: toggle ? 0 : -270 }}
      >
        <ul className="my-12">
          {langsMenu.map((lang, index) => (
            <li
              key={lang.langKey}
              className={classNames("px-5 py-3", {
                "border-b border-gray-500": index !== langsMenu.length - 1,
              })}
            >
              <Link className="text-gray-300 hover:text-white" to={lang.link}>
                {lang.langKey}
              </Link>
            </li>
          ))}
        </ul>
        <ul className="my-12">
          <li className="px-5 py-3 border-b border-gray-500">
            <Link
              className="text-gray-300 hover:text-white"
              to={localeSlug(langKey, "/")}
            >
              <FormattedMessage id="menuHome" />
            </Link>
          </li>
          <li className="px-5 py-3 border-b border-gray-500">
            <Link
              className="text-gray-300 hover:text-white"
              to={localeSlug(langKey, "/works")}
            >
              <FormattedMessage id="menuWorks" />
            </Link>
          </li>
          <li className="px-5 py-3 border-b border-gray-500">
            <Link
              className="text-gray-300 hover:text-white"
              to={localeSlug(langKey, "/photos")}
            >
              <FormattedMessage id="menuPhotos" />
            </Link>
          </li>
          <li className="px-5 py-3">
            <Link
              className="text-gray-300 hover:text-white"
              to={localeSlug(langKey, "/about")}
            >
              <FormattedMessage id="menuAbout" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default NavMobile;
