import React from "react";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";
import localeSlug from "../../utils/localeSlug";

function Nav({ langKey, langsMenu }) {
  return (
    <nav className="hidden md:flex flex-1 justify-between font-sans text-xs uppercase tracking-wider">
      <ul className="flex space-x-5">
        {langsMenu.map((lang) => (
          <li key={lang.langKey}>
            <Link className="text-gray-300 hover:text-white" to={lang.link}>
              {lang.langKey}
            </Link>
          </li>
        ))}
      </ul>
      <ul className="flex space-x-6">
        <li>
          <Link
            className="text-gray-300 hover:text-white"
            to={localeSlug(langKey, "/")}
          >
            <FormattedMessage id="menuHome" />
          </Link>
        </li>
        <li>
          <Link
            className="text-gray-300 hover:text-white"
            to={localeSlug(langKey, "/works")}
          >
            <FormattedMessage id="menuWorks" />
          </Link>
        </li>
        <li>
          <Link
            className="text-gray-300 hover:text-white"
            to={localeSlug(langKey, "/photos")}
          >
            <FormattedMessage id="menuPhotos" />
          </Link>
        </li>
        <li>
          <Link
            className="text-gray-300 hover:text-white"
            to={localeSlug(langKey, "/about")}
          >
            <FormattedMessage id="menuAbout" />
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
